export const basicFilterOperators = [
  { value: "=", label: "equal" },
  { value: "!=", label: "do NOT equal" },
  { value: "LIKE", label: "contains" },
  { value: "NOT LIKE", label: "does NOT contain" },
];

export const enumFilterOperators = [
  { value: "=", label: "equal", multi: false },
  { value: "!=", label: "do NOT equal", multi: false },
  { value: "IN", label: "is in", multi: true },
  { value: "NOT IN", label: "is NOT in", multi: true },
];

export const conversionFilterOperators = [
  { value: "IN", label: "is in", multi: true },
  { value: "NOT IN", label: "is NOT in", multi: true },
  { value: "=", label: "equal", multi: false },
  { value: "!=", label: "do NOT equal", multi: false },
];

export const dimensions = [
  { label: "Ad Group Name", value: "ad_group.name", operators: basicFilterOperators },
  { label: "Campaign Name", value: "campaign.name", operators: basicFilterOperators },
  { label: "Keyword", value: "keyword_view.resource_name", operators: basicFilterOperators },
  { label: "Search Terms", value: "search_term_view.search_term", operators: basicFilterOperators },
  {
    label: "Conversion Action (with selector)",
    value: "segments.conversion_action",
    operators: conversionFilterOperators,
    enum: [],
  },
  { label: "Conversion Action Name", value: "segments.conversion_action_name", operators: basicFilterOperators },
  {
    label: "Ad Network Type",
    value: "segments.ad_network_type",
    operators: enumFilterOperators,
    enum: [
      { label: "CONTENT", value: "CONTENT" },
      { label: "MIXED", value: "MIXED" },
      { label: "SEARCH", value: "SEARCH" },
      { label: "SEARCH_PARTNERS", value: "SEARCH_PARTNERS" },
      { label: "UNKNOWN", value: "UNKNOWN" },
      // {label: 'UNSPECIFIED', value: 'UNSPECIFIED'}, // Should be valid, but doesn't work. Fails at API level -> "Filtering by 'UNSPECIFIED' is not supported." --> {"error"=>{"code"=>400, "message"=>"Request contains an invalid argument.", "status"=>"INVALID_ARGUMENT", "details"=>[{"@type"=>"type.googleapis.com/google.ads.googleads.v8.errors.GoogleAdsFailure", "errors"=>[{"errorCode"=>{"queryError"=>"PROHIBITED_ENUM_CONSTANT"}, "message"=>"Filtering by 'UNSPECIFIED' is not supported."}], "requestId"=>"pLJUq9vR2rnQ2qZo8sFmog"}]}}
      { label: "YOUTUBE_SEARCH", value: "YOUTUBE_SEARCH" },
      { label: "YOUTUBE_WATCH", value: "YOUTUBE_WATCH" },
    ],
  },
  {
    label: "Device",
    value: "segments.device",
    operators: enumFilterOperators,
    enum: [
      { label: "CONNECTED_TV", value: "CONNECTED_TV" },
      { label: "DESKTOP", value: "DESKTOP" },
      { label: "MOBILE", value: "MOBILE" },
      { label: "OTHER", value: "OTHER" },
      { label: "TABLET", value: "TABLET" },
      { label: "UNKNOWN", value: "UNKNOWN" },
      { label: "UNSPECIFIED", value: "UNSPECIFIED" },
    ],
  },
  {
    label: "Match type",
    value: "segments.search_term_match_type",
    operators: enumFilterOperators,
    enum: [
      { label: "BROAD", value: "BROAD" },
      { label: "EXACT", value: "EXACT" },
      { label: "NEAR_EXACT", value: "NEAR_EXACT" },
      { label: "NEAR_PHRASE", value: "NEAR_PHRASE" },
      { label: "PHRASE", value: "PHRASE" },
      { label: "UNKNOWN", value: "UNKNOWN" },
      { label: "UNSPECIFIED", value: "UNSPECIFIED" },
    ],
  },
];

export const baseDimensions = [
  {
    label: "Conversion Action (with selector)",
    value: "segments.conversion_action",
    operators: conversionFilterOperators,
    enum: [],
  },
  { label: "Conversion Action Name", value: "segments.conversion_action_name", operators: basicFilterOperators },
  {
    label: "Ad Group Name",
    value: "ad_group.name",
    operators: basicFilterOperators,
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "ad_group.name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Age",
    value: "ad_group_criterion.age_range.type",
    operators: basicFilterOperators,
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "ad_group.name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Day of week",
    value: "segments.day_of_week",
    operators: enumFilterOperators,
    enum: [
      { label: "Monday", value: "MONDAY" },
      { label: "Tuesday", value: "TUESDAY" },
      { label: "Wednesday", value: "WEDNESDAY" },
      { label: "Thursday", value: "THURSDAY" },
      { label: "Friday", value: "FRIDAY" },
      { label: "Saturday", value: "SATURDAY" },
      { label: "Sunday", value: "SUNDAY" },
      { label: "Unknown", value: "UNKNOWN" },
      { label: "Unspecified", value: "UNSPECIFIED" },
    ],
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "ad_group.name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Device",
    value: "segments.device",
    operators: enumFilterOperators,
    filterableBy: ["ad_group.name", "campaign.name", "segments.device", "segments.conversion_action_name"],
    enum: [
      { label: "CONNECTED_TV", value: "CONNECTED_TV" },
      { label: "DESKTOP", value: "DESKTOP" },
      { label: "MOBILE", value: "MOBILE" },
      { label: "OTHER", value: "OTHER" },
      { label: "TABLET", value: "TABLET" },
      { label: "UNKNOWN", value: "UNKNOWN" },
      { label: "UNSPECIFIED", value: "UNSPECIFIED" },
    ],
  },
  {
    label: "Gender",
    value: "ad_group_criterion.gender.type",
    operators: basicFilterOperators,
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "ad_group.name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Hour",
    value: "segments.hour",
    operators: basicFilterOperators,
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "ad_group.name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Campaign Name",
    value: "campaign.name",
    operators: basicFilterOperators,
    filterableBy: [
      "campaign.name",
      "segments.device",
      "segments.ad_network_type",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Keyword",
    value: "keyword_view.resource_name",
    operators: basicFilterOperators,
    filterableBy: [
      "ad_group.name",
      "segments.ad_network_type",
      "campaign.name",
      "segments.device",
      "keyword_view.resource_name",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
  {
    label: "Search Terms",
    value: "search_term_view.search_term",
    operators: basicFilterOperators,
    filterableBy: [
      "ad_group.name",
      "segments.ad_network_type",
      "campaign.name",
      "segments.device",
      "segments.search_term_match_type",
      "search_term_view.search_term",
      "segments.conversion_action",
      "segments.conversion_action_name",
    ],
  },
];

//this listDimension is specificaly made for the list widget, it has one additionnal dimension
export const listDimensions = [
  {
    label: "Ad preview",
    value: "ad_group_ad.ad.name",
    operators: basicFilterOperators,
    filterableBy: ["ad_group.name", "campaign.name", "segments.device", "segments.conversion_action_name"],
  },
  ...baseDimensions,
];

export function getFilters(selectedDimension, conversionActions) {
  let dimensionsAvailable = [];
  let chosenDimension;
  if (selectedDimension == undefined) {
    return dimensionsAvailable;
  }

  //This checks if the chosen dimension is Period from the Bar graph as it has different filters.
  if (selectedDimension == "MW--period" || selectedDimension == "customer") {
    dimensionsAvailable = dimensions;
    //get index of Match Type dimension to remove it for the Bar graph
    let i = dimensionsAvailable.findIndex((x) => {
      return x.value == "segments.search_term_match_type";
    });
    //removing Match Type
    if (i > -1) {
      dimensionsAvailable.splice(i, 1);
    }
  } else {
    //this additional if serves to check the additional dimension Ad that is available in list widget
    if (selectedDimension == "ad_group_ad.ad.name") {
      listDimensions.forEach((x) => {
        if (x.value == selectedDimension) {
          chosenDimension = x;
        }
      });
    } else {
      baseDimensions.forEach((x) => {
        if (x.value == selectedDimension) {
          chosenDimension = x;
        }
      });
    }

    //double forEach in order to get all dimensions whose .value matches .filterableBy value of the chosen dimension
    dimensions.forEach((x) => {
      chosenDimension.filterableBy.forEach((y) => {
        if (y == x.value) {
          dimensionsAvailable.push(x);
        }
      });
    });
  }

  // Add conversion actions to the conversion action filter
  dimensionsAvailable.forEach((x) => {
    if (x.value == "segments.conversion_action") {
      x.enum = conversionActions;
    }
  });

  // Alphabetical sort
  dimensionsAvailable.sort((a, b) => (a.label > b.label ? 1 : -1));

  return dimensionsAvailable;
}
