import IntegrationBase from "../integration_base";
import IntegrationIcon from "./logo/google-ads.png";
import GoogleAdsMandatoryClickGIF from "./img/google-ads-mandatory-click.gif";
import { baseDimensions, conversionFilterOperators, dimensions } from "./filters_helper";
import React from "react";

export default class GoogleAds extends IntegrationBase {

  integrationConfig() {
    return {
      // Metrics Watch Integration Protcol Version.
      metricsWatchIntegrationProtocolVersion: "3.0.0",

      // This is enabling the use of generic widgets, instead of integration-specific widgets like we used to do.
      autoRegisterWidgets: { enabled: true },

      // This is enabling the autoloading of metrics. When enabled, we need a to set metricLoaderCallback to handle the API response and format the metrics.
      autoloadMetrics: { enabled: true, metricLoaderCallback: this.formatMetrics.bind(this) },

      // This is enabling the autoloading of metrics. When enabled, we need a to set dimensionLoaderCallback to handle the API response and format the metrics.
      autoloadDimensions: { enabled: false, },

      dimensions: () => {
        return baseDimensions
      },

      filterDimensionList: () => {
        return dimensions
      },

      // This is enabling the use of the generic account selector, instead of an integration-specific one.
      accountSelector: {
        enabled: true,
        databaseProviderType: "metrics_providers-google_ads",
        accountLoaderCallback: this.formatAccounts.bind(this),
      },

      metrics: () => {
        return this.metrics;
      },
      filterOperators: conversionFilterOperators,
      filterHasORenabled: true,
    };
  }

  // The name of this function is important. It's a hardcoded callback name used to autoload metrics.
  // This function is used to properly format the metrics returned for the integration, into the generic format.
  formatMetrics(metrics) {
    let formattedMetrics = [];

    for (let metric in metrics) {
      if (metrics[metric].metric_level.includes('customer')) {
        let label = metrics[metric].label;

        formattedMetrics.push({
          value: metric,
          label: label,
        });
      }
    }

    this.metrics = formattedMetrics;
  }

  name() {
    return "Google Ads";
  }

  iconURL() {
    return IntegrationIcon;
  }

  oAuthProviderName() {
    return "googleads";
  }

  oAuthProviderPreRedirectNotice() {
    return (
      <div className="text-center">
        <h2 className="text-4xl font-bold mb-12">Important notice</h2>
        <p className="my-2">
          To be able to access your Google Ads / Adwords data, on the next screen (on Google's consent screen), you will
          have to check this box:
        </p>
        <img className="border-2 border-red-400 m-auto" src={GoogleAdsMandatoryClickGIF} />
        <p className="my-2">Otherwise, we will not be able to pull your data.</p>
      </div>
    );
  }

  reducerAccountIDVariableName() {
    return "google_ads_customer_id";
  }

  reportStoreDispatchFunction() {
    return setGoogleAdsAccount;
  }

  formatAccounts(accounts) {
    let options = [];
    for (var i = 0; i < accounts.length; i++) {
      var provider = accounts[i];
      if (provider.type == "metrics_providers-google_ads" && Array.isArray(provider.attributes.provider_accounts)) {
        for (var j = 0; j < provider.attributes.provider_accounts.length; j++) {
          var acc = provider.attributes.provider_accounts[j];
          options.push({
            value: String(acc.google_ads_customer_id),
            label: acc.name,
            conversionActions: acc.conversion_actions
          });
        }
      }
    }

    store.dispatch(setIntegrationAccountList("googleads", options));
  }

  signInButton() {
    return (
      <button
        className="tw-submit-button"
        onClick={() => {
          window.location = this.oAuthProviderRedirectPath();
        }}
      >
        Continue
      </button>
    );
  }

  widgets() {
    return {
      integration: this,
      singleMetric: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "Google Ads Single Metric",
        widgetType: "google-ads-single-metric",
        defaultLabel: "Cost",
        defaultMetricName: "metrics.cost_micros",
      },
      list: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        sortEnabled: true, // if we want to to overwrite the sorting optins we need to set `this.sortByOptions` in this class (see how it's down in the `IntegrationBase` class)
        widgetName: "Google Ads List",
        widgetType: "google-ads-list",
        defaultLabel: "Campaigns",
        defaultDimensionName: "campaign.name",
        defaultDimensionLabel: "Campaign Name",
        defaultMetricName: "metrics.clicks",
        defaultMetricLabel: "Clicks",
      },
      pieAndDoughnut: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "Google Ads Pie Chart",
        widgetType: "google-ads-pie-chart",
        defaultLabel: "Clicks per campaign (Google Ads)",
        defaultDimensionName: "campaign.name",
        defaultDimensionLabel: "Campaign Name",
        defaultMetricName: "metrics.clicks",
        defaultMetricLabel: "Clicks",
      },
      mixedChart: {
        enabled: true,
        isBeta: false,
        soon: false,
        filtersEnabled: true,
        widgetName: "Google Ads Bar Graph",
        widgetType: "google-ads-bar-graph",
        defaultDimensionName: "campaign.name",
        defaultDimensionLabel: "Campaign Name",
        defaultMetricName: "metrics.clicks",
        defaultMetricLabel: "Clicks",
      },
    };
  }
}
